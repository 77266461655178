import React, { useEffect, useState, useContext, useRef } from "react";
import Seo from "../components/seo/seo";
import Layout from "../components/layout/layout";
import PageBanner from "../components/common/PageBanner";
import Clients from "../components/common/Clients";
import { GlobalStateContext } from "../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";

const Animations = () => {
  const state = useContext(GlobalStateContext);
  let containerAbout = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = containerAbout.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  const [showVid, setShowVid] = useState(false);

  useEffect(() => {
    setShowVid(true);
  }, []);

  return (
    <Layout>
      <Seo
        title='Animations | VIRSAT - Virtual Reality Safety Training'
        description='VIRSAT - Virtual Reality Safety Training structured learning processes use interactive animations. They deliver focused safety messages work to all workers.'
      />
      <PageBanner
        title='Animations'
        subtitle='Create hazard awareness'
        customClass='animation'
      />
      <section
        className='main-container animations hide-container'
        ref={containerAbout}
      >
        <div className='row top-pad fd-col bottom-pad'>
          <div className='column center'>
            <p className='medium no-mar reduce_width dark'>
              Social media deliver engaging, interactive, and personalized
              visual messages to your workers every day. Your safety
              communications can make use of the same technology.
            </p>
            <hr className='site-border' />
          </div>
          <div className='column details-image'>
            <div className='details'>
              <p>
                Our structured learning processes use interactive animations.
                They deliver focused safety messages work to all workers. A
                drastic reduction in serious incidents was proven to follow in a
                major Oil and Gas company.
              </p>
              <p>
                And… staff love it. We can help your company do the same. We
                have the tools, knowledge, and experience.
              </p>

              {/* <button className='secondary'>Watch Video</button> */}
            </div>
            <div className='image-container'>
              {showVid && (
                <iframe
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/_eVVzFfwVSE?rel=0'
                  title='YouTube video player'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
        </div>
      </section>
      <Clients />
    </Layout>
  );
};

export default Animations;
